import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import img1 from "../assets/header-logo.svg";
import img2 from "../assets/libertadores-logo.svg";
import { motion } from 'framer-motion'

const Header = ({ reset }) => {
		const footerAnimation = {
			in: {
				opacity: 1,
				transition: { delay: 1, duration: 0.8 },
			},
			out: {
				opacity: 0,
			},
			exit: {
				//
			},
		};
	return (
		<motion.nav
			className="header"
			initial="out"
			animate="in"
			variants={footerAnimation}
		>
			<div>
				<Link component={RouterLink} to="/" onClick={() => reset()}>
					<img
						src={img1}
						className="header__image header__image-1"
						alt="amstel"
					/>
				</Link>
			</div>
			<img src={img2} className="header__image header__image-2" alt="amstel" />
		</motion.nav>
	);
};

export default Header;

// llamar a la db para tener el objeto.
import { collection, onSnapshot } from "firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../lib/firebase";
import { exportCSVFile } from "../lib/convertTocsv";
import { motion } from "framer-motion";

const Download = () => {
	const [downloadedData, setDownloadedData] = useState([]);
	const [dataToString, setDataToString] = useState([]);

	//este evento se encarga de actualizar la base de datos para traer los documentos de la colección. Funciona como un hook.

	const headers = {
		nombre: "Nombre",
		telefono: "Teléfono",
	};

	useEffect(() => {
		snapShot();
	}, []);

	const snapShot = async () => {
		await onSnapshot(collection(db, "users"), (snapshot) => {
			setDownloadedData(
				snapshot.docs.map((doc) => {
					return doc.data();
				})
			);
		});
	};
	const dataRecycle = () => {
		//telefono 0 || nombre 1
		let array = downloadedData.map((user) => user.data.user);
		let sortedArray = array.map((dato) =>
			Object.entries(dato).sort((a, b) => {
				// tengo que ordenar lo que está dentro del objeto.
				if (a[0] === "name") return -1;
				if (a[1] === "name") return 0;
				return 0;
			})
		);

		let newArray = sortedArray.map((e) => e[0].concat(e[1]));
		let slicedArray = newArray.map((e) => e.slice(1, 4));
		slicedArray.map((e) => e.splice(1, 1));
		setDataToString(slicedArray);
	};

	return (
		<div
			style={{
				width: "100vw",
				height: "70vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<>
				{downloadedData.length > 0 && (
					<motion.button
						initial={{
							opacity: 0,
						}}
						animate={{
							opacity: 1,
						}}
						style={{
							backgroundColor: "#D6001C",
							border: "none",
							margin: "0.8rem 0",
							width: "28rem",
							height: "9.6rem",
							borderRadius: "4.8rem",
							fontSize: "3.2rem",
							cursor: "pointer",
							color: "#fff",
						}}
						onClick={() => dataRecycle()}
					>
						TRAER DATOS
					</motion.button>
				)}
				{dataToString.length > 0 && (
					<motion.button
						initial={{
							opacity: 0,
						}}
						animate={{
							opacity: 1,
						}}
						style={{
							backgroundColor: "#D6001C",
							border: "none",
							margin: "0.8rem 0",
							width: "28rem",
							height: "9.6rem",
							borderRadius: "4.8rem",
							fontSize: "3.2rem",
							color: "#fff",
							cursor: "pointer",
						}}
						onClick={() => exportCSVFile(headers, dataToString, "datosNew")}
					>
						DESCARGAR
					</motion.button>
				)}
			</>
		</div>
	);
};

export default Download;

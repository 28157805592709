import { useState } from "react";
import { useHistory } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import { AnimatedRoute } from "react-router-transition";
// import { useLocation } from "react-router-dom";

const Question = ({
	currentQuestion,
	setCurrentQuestion,
	selectedQuestions,
	options,
	correct,
	score,
	setScore,
	toggleNavi,
	onscroll,
}) => {
	const [choosen, setChoosen] = useState();
	const [error, setError] = useState(false);
	const [fireData, setFireData] = useState([]);

	// const location = useLocation();
	const history = useHistory();

	let toggleChild = toggleNavi();

	const handleSelect = (option) => choosen === option && "quiz__option-select";

	const handleCheck = (option, question) => {
		const newData = {
			question: question,
			answer: option,
		};
		setFireData([...fireData, newData]);
		setChoosen(option);
		setTimeout(() => {
			setCurrentQuestion(currentQuestion + 1);
			if (option === correct) setScore(score + 1);
			if (currentQuestion < 4) {
				setChoosen();
				toggleChild && toggle();
			} else if (currentQuestion === 4) {
				onscroll();
				history.push({ pathname: "/result", state: { detail: fireData } });
			}
			setError(false);
		}, 500);
	};

	const toggle = () => {
		toggleChild();
	};

	const questionTransition = {
		in: {
			opacity: 1,
			x: 0,
			transition: { ease: "easeOut", duration: 0.8 },
		},
		out: {
			opacity: 0,
			x: "-100px",
		},
	};

	const optionTransition = {
		in: (i) => ({
			opacity: 1,
			x: 0,
			transition: { ease: "easeOut", delay: i / 4, duration: 0.8 },
		}),
		out: {
			opacity: 0,
			x: "-100px",
		},
	};
	return (
		<div className="quiz__content">
			<motion.h1
				initial="out"
				animate="in"
				// exit="exit"
				variants={questionTransition}
				key={currentQuestion}
				className="quiz__question"
			>
				{currentQuestion <= 4 && selectedQuestions[currentQuestion].question}
			</motion.h1>
			<div className="quiz__options">
				{/* ERROR HANDLING */}
				{error && <h2>POR FAVOR SELECCIONA UNA PREGUNTA </h2>}
				{/* OPTIONS BEGINS HERE */}
				{options &&
					options.map((option, i) => {
						return (
							<AnimatePresence exitBeforeEnter>
								<motion.button
									onClick={() =>
										handleCheck(
											option,
											selectedQuestions[currentQuestion].question
										)
									}
									initial="out"
									animate="in"
									exit={{
										opacity: 0,
										x: "100px",
										transition: {
											ease: "easeOut",
											delay: i / 4,
										},
									}}
									variants={optionTransition}
									whileTap={{ scale: 1.05 }}
									key={option}
									custom={i}
									// eslint-disable-next-line react/jsx-no-duplicate-props
									disabled={choosen}
									className={`quiz__option ${choosen && handleSelect(option)}`}
								>
									{option}
								</motion.button>
							</AnimatePresence>
						);
					})}
			</div>
		</div>
	);
};

export default Question;

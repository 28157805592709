import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseApp = initializeApp({
	apiKey: "AIzaSyCHOm-Bb-tP9yJDiUrIKst4jar8k3q2ars",
	authDomain: "quizams.firebaseapp.com",
	projectId: "quizams",
	storageBucket: "quizams.appspot.com",
	messagingSenderId: "367178334042",
	appId: "1:367178334042:web:435e2e76e031f701bb3d63",
});

export const db = getFirestore(firebaseApp);

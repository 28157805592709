import { motion } from "framer-motion";
const FormNavigation = (props) => {
	const contentAnimation = {
		in: (i) => ({
			opacity: 1,
			x: 0,
			transition: { ease: "easeInOut", delay: 1, duration: 0.8 },
		}),
		out: {
			opacity: 0,
			x: 100,
		},
	};
	return (
		<div className="form__navigator">
			<motion.button
				initial="out"
				animate="in"
				variants={contentAnimation}
				className="form__button"
				type="submit"
				exit={{
					opacity: 0,
					x: 100,
					transition: { duration: 0.8 },
				}}
			>
				{props.isLastStep ? "Comenzar" : "Next"}{" "}
			</motion.button>
		</div>
	);
};

export default FormNavigation;

import { Link } from "react-router-dom";
import lata from "../assets/lata.png";
import latabk from "../assets/bk-lata.png";
import { motion } from "framer-motion";
import { uid } from "uid";
import { useEffect, useState } from "react";

const Home = ({ onscroll }) => {

	const footerAnimation = {
		in: {
			opacity: 1,
			transition: { delay: 1, duration: 0.8 },
		},
		out: {
			opacity: 0,
		},
		exit: {
			opacity: 0,
			transition: { delay: 1, duration: 0.8 },
		},
	};

	const contentAnimation = {
		in: (i) => ({
			opacity: 1,
			y: 0,
			transition: { ease: "easeOut", delay: i + 0.8, duration: 0.8 },
		}),
		out: {
			opacity: 0,
			y: 60,
		},
	};

	useEffect(() => {});
	return (
		<motion.div
			className="home"
		>
			<div className="home__container">
				<div>
					<motion.h1
						initial="out"
						animate="in"
						key={uid()}
						custom={1.2}
						exit={{
							opacity: 0,
							y: 80,
							transition: { delay: 0.8, ease: "easeOut", duration: 0.8 },
						}}
						variants={contentAnimation}
						className="home__title"
					>
						¡Descubrí cuán fanático sos
						<br /> de la Conmebol Libertadores!
					</motion.h1>
					<motion.button
						onClick={() => onscroll()}
						initial="out"
						animate="in"
						key={uid()}
						exit={{
							opacity: 0,
							y: 80,
							transition: { delay: 0.5, ease: "easeOut", duration: 0.8 },
						}}
						variants={contentAnimation}
						custom={1.6}
						className="home__button"
					>
						<Link className="home__span" to="/form">
							<span>¡Mucha suerte!</span>
						</Link>
					</motion.button>
				</div>
				<motion.footer
					initial="out"
					animate="in"
					key={uid()}
					exit="exit"
					variants={footerAnimation}
					className="home__footer"
				>
					<img src={lata} className="home__footer home__footer-1" alt="lata" />
					<img
						src={latabk}
						className="home__footer home__footer-2"
						alt="backgroundimg"
					/>
				</motion.footer>
			</div>
		</motion.div>
	);
};

export default Home;

import "./scss/styles.scss";
import { Switch, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import Home from "./pages/Home";
import Form from "./pages/Form";
import Quiz from "./pages/Quiz";
import Result from "./pages/Result";
import Download from "./pages/Download";
import Header from "./components/Header";
import { db } from "./lib/firebase";
import { doc, getDoc } from "@firebase/firestore";
import { setDoc } from "firebase/firestore";

export const App = () => {
	const [score, setScore] = useState(0);
	const [toggleBg, setToggleBg] = useState(false);
	const location = useLocation();

	const onscroll = () => {
		setTimeout(() => {
			const yellowbg = document.getElementById("wrapper");
			// la primer posibilidad es que toquen el home desde el home, no queremos que cambie el bool.
			if (toggleBg !== false && location.pathname === "/") {
				yellowbg.classList.remove("wrapper-scroll-up");
				yellowbg.classList.remove("wrapper-scroll-down");
				return;
				//de cualquier lado de la app que el boton no sea true (inicial state), excepto desde result
			} else if (toggleBg === false && location.pathname !== "/result") {
				yellowbg.classList.remove("wrapper-scroll-down");
				yellowbg.classList.add("wrapper-scroll-up");
				setToggleBg(true);
				//si es true no necesita hacer nada, sigue el link
			} else if (toggleBg === true && location.pathname === "/quiz") {
				yellowbg.classList.remove("wrapper-scroll-up");
				yellowbg.classList.add("wrapper-scroll-down");
				setToggleBg(false);
				return;
				// en el ultimo caso debe remover, lo que haria que baje el bg
			} else if (toggleBg === false && location.pathname === "/result") {
				setToggleBg(false);
				return;
			} else {
				yellowbg.classList.remove("wrapper-scroll-up");
				yellowbg.classList.add("wrapper-scroll-down");
				setToggleBg(false);
			}
		}, 1000);
	};
	const reset = () => {
		setTimeout(() => {
			setScore(0);
			const homebackbutton = document.getElementById("wrapper");
			homebackbutton.classList.remove("wrapper-scroll-up");
			homebackbutton.classList.add("wrapper-scroll-down");
			setToggleBg(false);
		}, 800);
	};
	const checksIfDataIsOnFirebase = async () => {
		let arrayToCheck = JSON.parse(localStorage.getItem("user"));
		// aca necesitamos hacer un map por cada elemento del local que tengamos y rescatar la UID, para luego consultar si ese uid existe en la base de datos.
		arrayToCheck &&
			arrayToCheck.map(async (user, i) => {
				const docRef = doc(db, "users", user.uidData);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					// si el documento existe, debo eliminar del array ese id.
					console.log("lo esta borrando!", user);
					arrayToCheck.splice(i, 1);
					localStorage.setItem("user", JSON.stringify(arrayToCheck));
				} else {
					// en caso de que no exista hay que tratar de pushearlo.
					await setDoc(doc(db, "users", user.uidData), {
						data: user,
						uid: user.uidData,
					});
					console.log("No hay mas elementos");
				}
			});
	};

	useEffect(() => {
		// cuando la pagina carga por primera vez tiene que bajar el bk amarillo, osea que necesitamos que la posicion iniicial de la parte amarilla este arriba y de ahi se desplace hacia abajo.
		const openningBackgroundEffect = () => {
			const onBackgroundEnter = document.getElementById("wrapper");
			onBackgroundEnter.classList.add("wrapper-on-enter");
		};
		openningBackgroundEffect();
		checksIfDataIsOnFirebase();
	}, []);
	return (
		<main className="wrapper" id="wrapper">
			<Header reset={reset} />
			<AnimatePresence exitBeforeEnter>
				<Switch location={location} key={location.pathname}>
					<Route path="/" exact>
						<Home onscroll={onscroll} />
					</Route>
					<Route path="/form" exact>
						<Form />
					</Route>
					<Route path="/Quiz" exact>
						<Quiz onscroll={onscroll} score={score} setScore={setScore} />
					</Route>
					<Route path="/result" exact>
						<Result onscroll={onscroll} score={score} setScore={setScore} />
					</Route>
					<Route path="/download" exact>
						<Download />
					</Route>
				</Switch>
			</AnimatePresence>
			{/* <Footer /> */}
		</main>
	);
};
export default App;

import { useLocation } from "react-router";
import lata from "../assets/lata.png";
import latabk from "../assets/bk-lata.png";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { uid } from "uid";

const Result = ({ score, setScore, onscroll }) => {
	const location = useLocation();

	const footerAnimation = {
		in: {
			opacity: 1,
			transition: { delay: 1, duration: 0.8 },
		},
		out: {
			opacity: 0,
		},
		// exit: {
		// 	opacity: 0,
		// 	transition: { delay: 1, duration: 0.8 },
		// },
	};

	const contentAnimation = {
		in: (i) => ({
			opacity: 1,
			y: 0,
			transition: { ease: "easeOut", delay: i + 0.8, duration: 0.8 },
		}),
		out: {
			opacity: 0,
			y: 60,
		},
	};

	const reset = (pathname) => {
		setScore(0);
		onscroll(pathname);
	};

	return (
		<div className="result">
			<div className="result__container">
				<AnimatePresence exitBeforeEnter>
					<motion.h1
						initial="out"
						animate="in"
						key={uid()}
						exit={{
							opacity: 0,
							y: 80,
							transition: { delay: 0.8, ease: "easeOut", duration: 0.8 },
						}}
						variants={contentAnimation}
						custom={1.2}
						className="result__title"
					>
						Tuviste {score} de 5 respuestas correctas
						<br />
						¡gracias por participar! <br />
					</motion.h1>
				</AnimatePresence>
				<AnimatePresence exitBeforeEnter>
					<motion.button
						initial="out"
						animate="in"
						exit={{
							opacity: 0,
							y: 80,
							transition: { delay: 0.5, ease: "easeOut", duration: 0.8 },
						}}
						key={uid()}
						variants={contentAnimation}
						onClick={() => reset(location.pathname)}
						custom={1.6}
						className="result__button"
					>
						<Link to="/">
							<span>Volver</span>
						</Link>
					</motion.button>
				</AnimatePresence>
				<AnimatePresence exitBeforeEnter>
					<motion.footer
						initial="out"
						animate="in"
						key={uid()}
						exit={{
							opacity: 0,
							transition: { duration: 0.8 },
						}}
						variants={footerAnimation}
						className="result__footer"
					>
						<img
							src={lata}
							className="result__footer result__footer-1"
							alt="lata"
						/>
						<img
							src={latabk}
							className="result__footer result__footer-2"
							alt="backgroundimg"
						/>
					</motion.footer>
				</AnimatePresence>
			</div>
		</div>
	);
};

export default Result;

import { useField } from 'formik';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import * as yup from 'yup';
import { uid } from 'uid';

import Wizard, { FormStep } from '../components/Wizard';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object({
  name: yup.string().required('Ingrese su nombre'),
  phoneNumber: yup.string().required('Ingrese su teléfono').matches(phoneRegExp, 'Por favor ingrese un número válido'),
});

const InputField = ({ label, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta] = useField(props);

  return (
    <>
      <input label={label} {...field} {...props} placerholder={props.placeholder} />
      {meta.touched && meta.error ? <div className="form__error">{meta.error}</div> : null}
    </>
  );
};
const Form = () => {
  const [uidData, setUidData] = useState();
  const history = useHistory();
  let user = '';

  useEffect(() => {
    setUidData(uid(16));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUidData]);

  const create = () => {
    let array2 = [];
    array2 = JSON.parse(localStorage.getItem('user'));
    if (!localStorage.getItem('user')) {
      localStorage.setItem('user', JSON.stringify([{ user, uidData: uidData }]));
    } else {
      array2.push({ user, uidData: uidData });
      localStorage.setItem('user', JSON.stringify(array2));
    }
  };
  // const putData = async (value) => {
  // 	try {
  // 		await setDoc(doc(db, "users", uidData), { data: value, uid: uidData });
  // 	} catch (e) {
  // 		console.error("Error adding document: ", e);
  // 	}
  // };
  const contentAnimation = {
    in: (i) => ({
      opacity: 1,
      y: 0,
      transition: { ease: 'easeOut', delay: i, duration: 0.8 },
    }),
    out: {
      opacity: 0,
      y: 60,
    },
  };
  return (
    <main className="form">
      <section className="form__container">
        <div className="form__content">
          <motion.h1
            initial="out"
            animate="in"
            key={uid()}
            custom={0.5}
            exit={{
              opacity: 0,
              y: 80,
              transition: { ease: 'easeOut', duration: 0.8 },
            }}
            variants={contentAnimation}
            className="form__title"
          >
            REGISTRATE PARA PARTICIPAR
          </motion.h1>
          <Wizard
            initialValues={{
              name: '',
              phoneNumber: '',
            }}
            onSubmit={(values) => {
              console.log(JSON.stringify(values, null, 2));
              user = values;
              create();
              //putData(values);
              history.push('/quiz');
            }}
          >
            <FormStep onSubmit={() => {}} validationSchema={validationSchema}>
              <motion.div
                className="form__wizard"
                initial="out"
                animate="in"
                exit={{
                  opacity: 0,
                  y: 80,
                  transition: { ease: 'easeOut', duration: 0.8 },
                }}
                key={uid()}
                custom={0.9}
                variants={contentAnimation}
              >
                <InputField className="form__input" name="name" placeholder="Nombre y apellido" label="Nombre"></InputField>
                <InputField className="form__input" name="phoneNumber" placeholder="Teléfono" label="Telefono"></InputField>
              </motion.div>
            </FormStep>
          </Wizard>
        </div>
      </section>
    </main>
  );
};

export default Form;

import { useEffect, useState } from "react";
import Question from "../components/Question";
import quiz from "../fixtures/quiz.json";
import { motion } from "framer-motion";

const Quiz = ({ score, setScore, onscroll }) => {
	const [options, setOptions] = useState();
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [selectedQuestions, setSelectedQuestions] = useState([]);

	useEffect(() => {
		function randomQuestions(quiz) {
			let arr = [];
			let counter = 0;
			while (counter < 5) {
				let rand = quiz[Math.floor(Math.random() * quiz.length)];
				if (!arr.some((an) => an === rand)) {
					arr.push(rand);
					counter++;
				}
			}
			setSelectedQuestions(arr);
		}
		randomQuestions(quiz.quiz);
	}, []);

	useEffect(() => {
		currentQuestion < 5 &&
			selectedQuestions.length > 0 &&
			setOptions(
				handleShuffle([
					selectedQuestions[currentQuestion]?.correct_answer,
					...selectedQuestions[currentQuestion]?.incorrect_answers,
				])
			);
		function handleShuffle(options) {
			return options.sort(() => Math.random() - 0.5);
		}
	}, [currentQuestion, selectedQuestions]);

	const toggleNavi = () => {
		if (currentQuestion <= 4) {
			let toggle = document.getElementById(currentQuestion);
			let toggleNext = document.getElementById(currentQuestion + 1);
			if (toggle) {
				toggle.classList.remove("quiz__toggle-active");
				toggleNext.classList.add("quiz__toggle-active");
			}
		}
	};

	return (
		<main className="quiz section">
			<div className="quiz__container">
				{selectedQuestions.length > 0 && currentQuestion <= 5 && (
					<Question
						currentQuestion={currentQuestion} // el numero de pregunta actual
						setCurrentQuestion={setCurrentQuestion} // elegir el numero
						selectedQuestions={selectedQuestions} // las seleccionadas aleatoriamente
						options={options} // las opciones aleatoriamente ubicadas de esa pregunta
						correct={selectedQuestions[currentQuestion]?.correct_answer} // la respuesta correcta
						score={score}
						toggleNavi={toggleNavi}
						setScore={setScore}
						onscroll={onscroll}
					/>
				)}
				<div className="quiz__pagination">
					<div className="quiz__toggle quiz__toggle-active" id="1"></div>
					<div className="quiz__toggle" id="2"></div>
					<div className="quiz__toggle" id="3"></div>
					<div className="quiz__toggle" id="4"></div>
					<div className="quiz__toggle" id="5"></div>
				</div>
			</div>
		</main>
	);
};

export default Quiz;
